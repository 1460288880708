ul::-webkit-scrollbar { width: 4px; }

.MuiAutocomplete-inputRoot {
  flex-wrap: nowrap !important;
}

#watchlist-container {
  color: white;
  border: 1px solid rgb(67, 68, 203);
  overflow: hidden;
  overscroll-behavior-x: contain;
  overscroll-behavior-y: contain;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
}

#watchlist-title {
  display: flex;
  z-index: 4;
  height: 15%;
  width: 100%;
  position: relative;
  top: 0;
  background-color: rgb(36, 35, 107);
  border-bottom: 1px solid #5A5A5A;
}

#title-text-container {
  float: left; 
  width: 200px;
  display: table;
}

#title-text {
  font-weight:normal;
  display: table-cell; 
  text-align: left;
  vertical-align: middle;
  padding-left: 1vw;
}

#add-symbol-button-container {
  margin-left: auto;
  float: right;
  order: 1;
  align-self: center;  
}

#add_symbol_button {
  padding: 0;
  min-width: 28%;
  min-height: 50%;
  margin: auto;
  background-color: transparent;
}
#symbol-selector-container {
  order: 2;
  align-self: center;
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  margin-bottom: auto;
}

#popout-button {
  order: 3;
  width: 25%;
  align-self: center;
  float: right;
}

#watchlist-table-container {
  overflow-x: scroll;
  overflow-y: scroll;
  
  overscroll-behavior-x: contain;
  overscroll-behavior-y: contain;
  bottom: 0;
  color: white;
  height: 85%;
  width: 100%;
}

#watchlist-table-container::-webkit-scrollbar { 
  background: rgb(26, 26, 77);
  width: 0.3vw;
  height: 0.3vw;
}

#watchlist-table-container::-webkit-scrollbar-thumb {
  background: 	#5A5A5A;
  border-radius: 10px;
}

#watchlist-table-container::-webkit-scrollbar-thumb:hover {
  background: rgb(67, 68, 203);
}

#watchlist-table-container::-webkit-scrollbar-corner { 
  background: rgb(26, 26, 77);
}

#watchlist-table {
  color: white;
  float: bottom;
  width: 100%;
}

.stick-to-top {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  background-color: rgb(26, 26, 77) !important;
  color: rgb(0, 151, 151);
  z-index: 2;
}

.stick-to-left {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: rgb(16, 16, 46);
  z-index: 1;
}

.symbol-column-title-text {
  cursor: default;
  margin: 8px 0;
}

.symbol-container {
  float: right;
  text-align: center;
  overflow: hidden;
  height: 100%;
  vertical-align: middle;
  padding-right: 10px;
}

.symbol-title {
  height: 30%;
}

.symbol-cell {
  height: 50px; 
  min-width: 110px;
  vertical-align: middle;
}

.overlay {
  z-index: 3;
}

.watchlist-row > td {
  white-space: nowrap;
  text-align: center;
  padding: 0 20px;
  font-size: 14px;
  font-weight: lighter;
}

.watchlist-row {
  height: 20px;
  line-height: 20px;
}