.TVChartContainer {
	height: 100%;
  width: 100%;
  border: 1px solid rgb(67, 68, 203);
}

.TVChartContainer-popout {
	height: 92vh;
  width: 98vw;
  border: 1px solid rgb(67, 68, 203);
  margin: calc(0vh) calc(1vw - 1px) calc(1vh - 1px) calc(1vw - 1px); 
}

#pop-out-button {
  width: 10%;
  height: 5%;
}

#pop-in-button {
  height: 5vh;
  width: 10vw;
}

#chart-pop-out-container {
  text-align: center;
  width: 100vw;
}

#chart-pop-in-container {
  text-align: center;
  height: 96%;
  width: 100%;
}

#tradingview-link-container {
  text-align: right;
  color:rgb(0, 151, 151);
}

#tradingview-link:visited {
  color:rgb(0, 151, 151);
}

#tradingview-link:hover {
  color: rgb(67, 68, 203);
}