#newsfeed-container {
  color: white; 
  border: 1px solid rgb(67, 68, 203);
  width: 100%;
  height: 97%;
  position: relative;
  overflow: hidden;
  background-color: rgb(36, 35, 107);
  font-family: "Trebuchet MS", Helvetica, sans-serif;
}

#crypto-news-posts {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  background-color: rgb(16, 16, 46);
  padding-right: 0;
  margin: 0;
  height: 85%;
  width: 100%;
  border-top: 1px solid #5A5A5A !important;
}

ul {
  list-style-type: none;
}

#newsfeed-title-container {
  float: left;
  display: table;
  height: 15% !important;
}

#newsfeed-title {
  display: table-cell; 
  text-align: left;
  vertical-align: middle;
  height: 100%;
  width: 25%;
  font-weight:normal;
  padding-left: 1vw;
}

#crypto-news-posts::-webkit-scrollbar { 
  background: rgb(26, 26, 77);
  width: 0.3vw;
  height: 0.3vw;
}

#crypto-news-posts::-webkit-scrollbar:hover { 
  border: 0;
}

#crypto-news-posts::-webkit-scrollbar-thumb {
  background: #5A5A5A;
  border-radius: 10px;
}

#crypto-news-posts::-webkit-scrollbar-thumb:hover {
  background: rgb(67, 68, 203);
}

#crypto-news-posts::-webkit-scrollbar-corner { 
  background: rgb(26, 26, 77);
}

.news-story-container {
  padding: 0;
}

.news-story-title {
  color: rgb(0, 151, 151);
  background-color: rgb(26, 26, 77);
  width: 98%;
  height: 96%;
  padding: 2% 0 2% 2%;
  margin: 0;
}

.news-story-point {
  font-size: 14px;
  font-weight: lighter;
}

.crypto-news-link {
  color: rgb(0, 151, 151);
  cursor: pointer;
  text-decoration: underline;
}

.crypto-news-link:hover {
  color: rgb(67, 68, 203);
}
