#login-container {
  height: 100%;
  width: 100%;
  text-align: left;
  margin-top: 25px;
}

#login-title {
  color: white;
}

#login-description {
  color: white;
}

#login-form {
  height: 100%;
  width: 100%;
}

.form-group {
  margin-bottom: 10px;
}

.Input {
  width: 40vw;
  height: 3vh;
}

#submit-button {
  margin: 0 auto 0 auto;
}