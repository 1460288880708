@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto-Regular-webfont.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  background: #0F0F2B;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 80%;
}

html, body {
  overflow: none;
  overscroll-behavior-x: contain;
}