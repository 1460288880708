.column-text {
  margin: 0px 0 0px 0;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
}

.metric-title {
  white-space: nowrap;
  height: 25%;
  text-align: right;
  padding: 0;
}

